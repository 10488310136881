import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import ChatMessage from './chatMessage'
import { useMain } from '../../stateManagement/contexts/mainContext'
import AssistantStartup from './assistantStartup.tsx'
import { iSession } from './iSession.ts'

import useUserProfileStore from '../../stateManagement/userProfileState.ts'
import './chatWindow.css'
import ChatMessageInstructions from '../emptyStates/chatMessageInstructions.tsx'
import { shallow } from 'zustand/shallow'

type ChatWindowContentProps = {
  session: iSession
  displayType: 'intro' | 'assistant' | 'chat' | null
}

const ChatWindowContent = ({ session, displayType }: ChatWindowContentProps) => {
  const { showSettings, setShowSettings } = useMain()
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const chatWindowBottomAnchor = React.useRef<HTMLDivElement>(null)

  const toggleSettingsSection = () => {
    setShowSettings(!showSettings)
  }

  const scrollToBottomOfChatWindow = () => {
    if (chatWindowBottomAnchor.current) {
      chatWindowBottomAnchor.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const willMessageTransfered = useCallback(
    (index: number, single: boolean = false) => {
      if (session?.assistantSettings?.settings?.transferLength === 21) return !single
      const length =
        session!.messages!.length! -
        session!.assistantSettings!.settings!.transferLength!
      return single ? length === index : length <= index
    },
    [],
  )

  useEffect(() => {
    if (session.isGenerating) {
      scrollToBottomOfChatWindow()
    }
  }, [session.isGenerating, session.messages?.length])

  useEffect(() => {
    if (displayType === 'chat') {
      setTimeout(() => scrollToBottomOfChatWindow(), 200)
    }
  }, [displayType])
  if (displayType === 'assistant') {
    return <AssistantStartup />
  } else if (displayType === 'chat') {
    return (
      <div className={'grid place-content-top place-items-stretch w-full'}>
        {session?.messages?.map((message, index) => {
          return (
            <React.Fragment
              key={session.id + '_' + message.id + '_' + message.tmpId}
            >
              {willMessageTransfered(index, true) && (
                <div className={`divider pt-6 transition`}>
                  <div
                    className={
                      'flex flex-col items-center justify-center text-gray-400'
                    }
                  >
                    <div className={'flex-1'}>Transferred</div>
                    <ChevronDownIcon className={'flex-1 w-5 h-5'}></ChevronDownIcon>
                  </div>
                </div>
              )}
              <ChatMessage
                transfered={willMessageTransfered(index)}
                message={message}
                isLastMessage={session.messages!.length - 1 === index}
                sessionId={session.id!}
                userId={userProfile!.id!}
              />
            </React.Fragment>
          )
        })}
        <div
          id={'chatWindowBottomAnchor_' + session.id}
          ref={chatWindowBottomAnchor}
        />
      </div>
    )
  } else if (displayType === 'intro') {
    return <ChatMessageInstructions toggleSettingsSection={toggleSettingsSection} />
  }
}

export default ChatWindowContent
