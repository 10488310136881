import React, { useEffect, useState } from 'react'
import SearchInput from '../../utils/searchInput.tsx'
import { iTool } from '../tools/iTool.ts'
import ToolService from '../../../services/toolService.ts'
import ToolCard from './toolCard.tsx'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { shallow } from 'zustand/shallow'
import SecretService from '../../../services/secretService.ts'
import { iSecret } from '../../utils/iSecret.ts'
export interface iToolViewProps {
  goNextFunction: () => void
  goBeforeFunction: () => void
}

function ToolView({ goBeforeFunction }: iToolViewProps) {
  const [tools, setTools] = useState<iTool[]>([])
  const [secrets, setSecrets] = useState<iSecret[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { activeSession, updateSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      activeSession: state.activeSession,
    }),
    shallow,
  )

  /**
   * Reload tools when the selected assistant changes
   */
  useEffect(() => {
    loadTools()
  }, [activeSession, activeSession?.assistantSettings?.baseAssistantSettingsId])

  const loadTools = () => {
    setLoading(true)
    ToolService.getTools(
      activeSession?.assistantSettings?.baseAssistantSettingsId ?? '',
    ).then((response) => {
      if (!activeSession) {
        return
      }
      let toolsResponse = response.map((blob) => {
        const selected =
          activeSession.assistantSettings!.toolIds?.includes(blob.id!) ?? false
        return { ...blob, selected: selected }
      })
      // filter out duplicates, keep the one with fromBaseAssistantInherited = true
      toolsResponse = Object.values(
        toolsResponse.reduce(
          (acc, tool) => {
            if (!acc[tool.id!] || tool.fromBaseAssistantInherited) {
              acc[tool.id!] = tool
            }
            return acc
          },
          {} as { [id: string]: iTool },
        ),
      )

      setLoading(false)
      setTools(toolsResponse)
    })
  }

  const filteredTools = () => {
    if (searchTerm === '' || searchTerm.length < 3) {
      return tools
    } else {
      return tools.filter((tool) => {
        return (
          tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tool.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
    }
  }
  useEffect(() => {
    SecretService.getSecrets().then((secrets) => {
      setSecrets(secrets)
    })
  }, [])

  const handleSelect = (tool: iTool) => {
    // prevent the uncheck if it is from base assistant inherited
    if (tool.fromBaseAssistantInherited) return

    const updatedTools = tools.map((tool) => {
      if (tool.id === tool.id) {
        return { ...tool, selected: !tool.selected }
      }
      return tool
    })

    setTools(updatedTools)

    const selectedToolsList = updatedTools.filter((tool) => tool.selected)
    if (activeSession) {
      activeSession.assistantSettings!.toolIds = selectedToolsList.map(
        (tool) => tool.id!,
      )
      updateSession(activeSession)
    }
  }

  return (
    <>
      <div className="space-y-4 mx-auto  p-4 rounded-xl  sticky top-0 z-10 w-full max-h-fit  flex justify-center">
        <div className={'h-full justify-center flex flex-col-reverse'}>
          <button
            onClick={() => goBeforeFunction()}
            className={
              'btn-glass px-2 max-h-12 rounded-lg hover:-translate-y-1 hover:scale-110 duration-300 indicator'
            }
          >
            <ArrowLeftIcon
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            />
          </button>
        </div>
        <div className={'w-full'}>
          <div className={'justify-center'}>
            <h1 className={'text-xl font-semibold mb-2'}>Tools</h1>
            <p className={''}>Select the tool you want to add to the session. </p>
          </div>
          {(loading && (
            <div className={'grid grid-cols-1 h-20'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )) ||
            (tools.length > 0 && (
              <>
                {tools.length > 3 && (
                  <SearchInput
                    searchTerm={searchTerm}
                    placeholder={'Search for tool or description'}
                    setSearchTerm={setSearchTerm}
                  />
                )}

                <div className="flex flex-wrap justify-center overflow-y-auto">
                  {filteredTools().map((tool, key) => {
                    return (
                      <ToolCard
                        key={key}
                        tool={tool}
                        secrets={secrets}
                        handleSelect={handleSelect}
                      />
                    )
                  })}
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  )
}

export default ToolView
