import React, { useState } from 'react'
import {
  ArrowPathIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  SpeakerWaveIcon,
  StopIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import './mermaid-custom.css'
import { useMessages } from '../../stateManagement/contexts/messageContext.tsx'
import { iSession } from './iSession.ts'
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tooltip,
} from '@nextui-org/react'
import './chatMessage.css'
import { iMessage } from './iMessage.ts'
import { TextToSpeech } from '../../utils/TextToSpeech.ts'
import useSignalRStore from '../../stateManagement/signalRState.ts'
import useUserProfileStore from '../../stateManagement/userProfileState.ts'
import { hasFeatureFlag } from '../../utils/featureFlags.ts'
import ClipboardCopyButton from '../utils/copyToClipboard.tsx'
import { shallow } from 'zustand/shallow'

type ChatMessageHeaderProps = {
  session: iSession
  message: iMessage
  disableButtons?: {
    delete?: boolean
    edit?: boolean
    copy?: boolean
    speech?: boolean
    regenerate?: boolean
  }
  hideButtons?: {
    delete?: boolean
    edit?: boolean
    copy?: boolean
    speech?: boolean
    regenerate?: boolean
  }
  onEditClick: () => void
}

function ChatMessageHeader({
  session,
  message,
  disableButtons,
  hideButtons,
  onEditClick,
}: ChatMessageHeaderProps) {
  const { deleteMessage } = useMessages()
  const [deletingMessage, setDeletingMessage] = useState<boolean>(false)
  const [isTalking, setIsTalking] = useState<boolean>(false)
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const startAssistant = useSignalRStore((state) => state.startAssistant, shallow)

  const getJoinedMessageContent = () => {
    return (
      message.content ??
      message.contentItems?.map((item) => item.content).join('\n') ??
      ''
    )
  }

  const handleEdit = () => {
    onEditClick()
  }

  const handleDelete = async () => {
    setDeletingMessage(true)
    try {
      return await deleteMessage(session as iSession, message)
    } finally {
      setDeletingMessage(false)
    }
  }

  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]))

  const synth = new TextToSpeech()

  const handleTextToSpeech = () => {
    if (!message.content) return
    if (!isTalking) {
      const valuesArray = [...selectedKeys.values()]
      const voiceUri = valuesArray[0]
      synth.setDefaultVoice(
        synth.getVoiceCollection().find((v) => v.voiceURI === voiceUri)!,
      )
      synth.talk(message.content, () => {
        setIsTalking(false)
      })
      setIsTalking(true)
    } else {
      synth.cancel()
      setIsTalking(false)
    }
  }

  const handleRegenerate = () => {
    const dummyMessage: iMessage = { role: 'assistant', sessionId: session.id! }
    session.messages!.push(dummyMessage)
    session.isGenerating = true
    session.isAssistantRunning = true
    handleDelete().then(() => {
      startAssistant(session.id!, userProfile!.id!)
    })
  }

  const deleteButton = (
    <Tooltip content="Delete" color={'primary'} className={'max-w-96'}>
      <Button
        isIconOnly
        isDisabled={deletingMessage || disableButtons?.delete}
        variant={'light'}
        color={'primary'}
        className={`bg-ghost hover:text-error ${message.role === 'user' ? 'mr-0' : 'mr-0'}`}
        onClick={handleDelete}
      >
        <XMarkIcon className="h-5 w-5" />
      </Button>
    </Tooltip>
  )
  const copyButton = (
    <ClipboardCopyButton textToCopy={getJoinedMessageContent()} tooltip={'Copy'} />
  )
  const editButton = (
    <Tooltip content="Edit" color={'primary'} className={'max-w-96'}>
      <Button
        isIconOnly
        isDisabled={deletingMessage || disableButtons?.edit}
        variant={'light'}
        color={'primary'}
        className="bg-ghost mr-0"
        onClick={handleEdit}
      >
        <PencilSquareIcon className="h-5 w-5" />
      </Button>
    </Tooltip>
  )

  const textToSpeech = hasFeatureFlag() && (
    <Badge
      color={'primary'}
      variant={'flat'}
      placement={'bottom-right'}
      size={'sm'}
      showOutline={false}
      content={
        <Dropdown isDisabled={session.isGenerating}>
          <DropdownTrigger className={'p-0 min-w-0'}>
            <ChevronDownIcon className="cursor-pointer h-5 w-3 min-w-0"></ChevronDownIcon>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Single selection example"
            variant="flat"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys as any}
          >
            {synth.getVoiceCollection().map((voice) => {
              return (
                <DropdownItem key={voice.voiceURI}>{voice.voiceURI}</DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      }
    >
      <Tooltip content="Read Aloud" color={'primary'} className={'max-w-96'}>
        <Button
          isDisabled={deletingMessage || disableButtons?.speech}
          variant={'light'}
          color={'primary'}
          className="bg-ghost min-w-10 px-0"
          onClick={handleTextToSpeech}
        >
          {!isTalking ? (
            <SpeakerWaveIcon className="h-5 w-5" />
          ) : (
            <StopIcon className="h-5 w-5" />
          )}
        </Button>
      </Tooltip>
    </Badge>
  )

  const regenerateAssistantMessage = (
    <Tooltip content="Regenerate" color={'primary'} className={'max-w-96'}>
      <Button
        isIconOnly
        isDisabled={deletingMessage || disableButtons?.regenerate}
        variant={'light'}
        color={'primary'}
        className="bg-ghost mr-4"
        onClick={handleRegenerate}
      >
        <ArrowPathIcon className="h-5 w-5" />
      </Button>
    </Tooltip>
  )
  return (
    <div className="chat-header w-full">
      <div
        className={`transition group-hover:-translate-y-[10px] translate-y-[100%] ${message.role === 'user' ? 'flex justify-end' : ''}`}
      >
        <ul className={''}>
          {message.role === 'assistant' ? (
            <>
              {deleteButton}
              {copyButton}
              {editButton}
              {hasFeatureFlag() && textToSpeech}
              {!hideButtons?.regenerate && regenerateAssistantMessage}
            </>
          ) : (
            <>
              {editButton}
              {copyButton}
              {deleteButton}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ChatMessageHeader
