import React, { useEffect, useState } from 'react'
import { iMessage } from './iMessage'

import { useMessages } from '../../stateManagement/contexts/messageContext'
import { useMain } from '../../stateManagement/contexts/mainContext'
import { AssistantStatus, iAssistantAction, iSession } from './iSession.ts'
import { SignalRStatus } from '../../enums/signalRStatus.ts'

import useSignalRStore, {
  iAssistantPayload,
} from '../../stateManagement/signalRState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import useUserProfileStore from '../../stateManagement/userProfileState.ts'
import './chatWindow.css'
import ChatWindowMessageBar from './chatWindowMessageBar.tsx'
import ChatWindowContent from './chatWindowContent.tsx'
import { shallow } from 'zustand/shallow'
import SessionService from '../../services/sessionService.ts'

interface iChatWindowProps {
  theme: string
  session: iSession
}

function ChatWindow({ session }: iChatWindowProps) {
  const { updateSession, updateSessionInternal } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      updateSessionInternal: state.updateSessionInternal,
    }),
    shallow,
  )

  const { measureAndSendGenerationTime } = useMessages()
  const { signalRstatus, connection, joinSession } = useSignalRStore(
    (state) => ({
      signalRstatus: state.signalRstatus,
      connection: state.connection,
      joinSession: state.joinSession,
    }),
    shallow,
  )
  const { setError } = useMain()
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const messageAnchor = React.useRef<HTMLDivElement>(null)

  const [hasReconnected, setHasReconnected] = useState(false)

  const [displayType, setDisplayType] = useState<
    'intro' | 'assistant' | 'chat' | null
  >(null)
  const [_, setAppearingMessage] = useState<iMessage | null>(null)
  const [assistantPayload, setAssistantPayload] = useState<iAssistantPayload | null>(
    null,
  )

  // const [content, setContent] = React.useState('');

  const upsertSessionMessage = (
    session: iSession,
    message: iMessage,
  ): iMessage[] => {
    let messages: iMessage[] = []
    if (session.messages) {
      messages = [...session.messages]
    }

    const messageToUpdate = messages.find(
      (m) => m.id === message.id && m.role === 'assistant',
    )
    if (!messageToUpdate) {
      //check if empty assistant message exists
      const emptyAssistantMessageIndex = messages.findIndex(
        (m) => !m.id && m.role === 'assistant',
      )
      if (emptyAssistantMessageIndex > -1) {
        messages[emptyAssistantMessageIndex] = message
        return messages
      }
      // append message
      messages.push(message)
    } else {
      // update message
      messages = messages!.map((m) => (m.id === message.id ? message : m))
    }

    return messages
  }

  const onSessionMessageReceived = (message: iMessage) => {
    if (!session) return
    if (message.sessionId !== session.id) return
    session.isGenerating = true
    session.isAssistantRunning = true
    session.messages = upsertSessionMessage(session, message)
    document
      .getElementById(`chatWindowBottomAnchor_${session.id}`)
      ?.scrollIntoView(true)
    setAppearingMessage(message) // triggers re-render
  }

  const onCompletionFinished = (message: iMessage) => {
    if (!session) return
    if (message.sessionId !== session.id) return

    session.messages = upsertSessionMessage(session, message)
    session!.isGenerating = false
    measureAndSendGenerationTime(session)
    setAppearingMessage(message) // triggers re-render
  }

  useEffect(() => {
    if (signalRstatus === SignalRStatus.Reconnecting) {
      setHasReconnected(true)
    }

    // get messages after reconnection
    if (signalRstatus === SignalRStatus.Connected && hasReconnected) {
      SessionService.getMessages(session.id!).then((messages) => {
        session.isGenerating = false
        session.isAssistantRunning = false
        session.messages = messages
        updateSessionInternal(session)
        setHasReconnected(false)
      })
    }
  }, [signalRstatus])

  // Subscribe to the group (session) when the component mounts and unsubscribe when it unmounts
  useEffect(() => {
    if (!session || !connection || signalRstatus !== SignalRStatus.Connected) return

    joinSession(session.id!, userProfile!.id!)
    const onCompletionUpdate = (message: iMessage) =>
      onSessionMessageReceived(message)
    const onCompletionEnd = (message: iMessage) => onCompletionFinished(message)
    const onCompletionError = (e: string) => {
      setError(e)
    }
    const onAssistantError = (e: string) => {
      setError(e)
      session.isGenerating = false
      session.assistantStatus = AssistantStatus.Idle
      measureAndSendGenerationTime(session)
    }
    const onAssistantCancelled = (e: string) => {
      setError(e)
      session.isGenerating = false
      session.assistantStatus = AssistantStatus.Idle
      measureAndSendGenerationTime(session)
    }
    const onAssistantStart = (assistantPayload: iAssistantPayload) => {
      setAssistantPayload(assistantPayload)
    }
    const onAssistantAction = (action: iAssistantAction) => {
      session.currentAssistantAction = action
      updateSessionInternal(session)
    }
    const onAssistantEnd = () => {
      session.isAssistantRunning = false
      session.currentAssistantAction = undefined
      updateSessionInternal(session)
    }
    const onTitleUpdate = ({
      title,
      sessionId,
    }: {
      title: string
      sessionId: string
    }) => {
      if (session && session.id === sessionId) {
        session.title = title
        updateSession(session)
      }
    }

    connection.on('completionUpdate', onCompletionUpdate)
    connection.on('completionEnd', onCompletionEnd)
    connection.on('completionError', onCompletionError)
    connection.on('assistantError', onAssistantError)
    connection.on('assistantCancelled', onAssistantCancelled)
    connection.on('assistantStart', onAssistantStart)
    connection.on('assistantEnd', onAssistantEnd)
    connection.on('sessionTitle', onTitleUpdate)
    connection.on('assistantAction', onAssistantAction)

    // Cleanup function
    return () => {
      connection.off('completionUpdate', onCompletionUpdate)
      connection.off('completionEnd', onCompletionEnd)
      connection.off('completionError', onCompletionError)
      connection.off('assistantError', onAssistantError)
      connection.off('assistantCancelled', onAssistantCancelled)
      connection.off('assistantStart', onAssistantStart)
      connection.on('assistantEnd', onAssistantEnd)
      connection.off('sessionTitle', onTitleUpdate)
      connection.off('assistantAction', onAssistantAction)
    }
  }, [session, connection, signalRstatus])

  useEffect(() => {
    const messages = session?.messages?.filter(
      (m: iMessage) => m.contentType !== 'systemMessage',
    )
    if (!messages?.length) setDisplayType('assistant')
    else if (messages && messages.length > 0) setDisplayType('chat')
    else setDisplayType('intro')
    setError(null)
  }, [session, session.messages?.length])

  const createDummyAssistantMessage = () => {
    const dummyMessage: iMessage = { role: 'assistant', sessionId: session.id! }
    session.isGenerating = true
    session.isAssistantRunning = true
    session.messages = upsertSessionMessage(session, dummyMessage)
  }
  return (
    session && (
      <div className="chat-window-wrapper">
        <div
          className={`chat-window flex pt-2 flex-col max-w-full w-full mx-auto px-2 sm:px-4 absolute h-full overflow-auto`}
          id={'chat-window_' + session.id}
        >
          <div
            className={` w-full flex-1 chat-content`}
            ref={messageAnchor}
            id={'chatMessages'}
          >
            <ChatWindowContent
              displayType={displayType}
              session={session}
            ></ChatWindowContent>
          </div>
          <ChatWindowMessageBar
            assistantPayload={assistantPayload}
            displayType={displayType}
            changeDisplayType={(type) => setDisplayType(type)}
            session={session}
            afterSendUserMessage={createDummyAssistantMessage}
          ></ChatWindowMessageBar>
        </div>
      </div>
    )
  )
}

export default ChatWindow
